import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { Refine } from '@pankod/refine-core';
import { notificationProvider } from '@pankod/refine-antd';
import { RefineKbar, RefineKbarProvider } from '@pankod/refine-kbar';
import routerProvider from '@pankod/refine-react-router-v6';

import { Title, Header, Footer, Layout, Sider, OffLayoutArea, ErrorPage } from 'components/layout';

import {
  accessControl,
  localStrategy,
  authProvider,
  dataProvider,
  resources,
  shrimpHarvestProvider,
} from 'libs/provider';
import { SERVICE } from 'config/service';

import 'react-quill/dist/quill.snow.css';
import 'dayjs/locale/id';
import 'styles/global.less';
import 'styles/antd.less';
import { promoProvider } from 'libs/provider/promoProvider';
import routes from 'routes';
import { FEATURE } from 'config/feature';

import * as Sentry from '@sentry/react';
import DashboardPage from 'pages/dashboard';
import Login from 'pages/login';
import { crmProvider } from 'libs/provider/crmProvider';

import { useAuth } from '@efishery/sdk-reactjs';
import { ENV } from '@config/env';
import { LoadingScreen } from '@components/loading';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.tz.setDefault('Asia/Jakarta');

const App: React.FC = () => {
  const { authenticateStatus } = useAuth();

  if (authenticateStatus === 'loading') {
    return <LoadingScreen />;
  }

  return (
    <RefineKbarProvider>
      <Refine
        authProvider={authProvider}
        accessControlProvider={
          ENV.ENABLE_ACCESS_CONTROL_LOCAL_STRATEGY ? localStrategy : accessControl
        }
        dataProvider={{
          default: dataProvider(SERVICE.FISH),
          v2_fish_service: dataProvider(SERVICE.FISH_V2),
          support_service: dataProvider(SERVICE.SUPPORT),
          support_service_inter: dataProvider(SERVICE.SUPPORT_INTER),
          customer_service: dataProvider(SERVICE.CUSTOMER),
          customer_service_inter: dataProvider(SERVICE.CUSTOMER_INTER),
          auth_service: dataProvider(SERVICE.AUTH),
          fresh_service: dataProvider(SERVICE.FRESH),
          promo_service: promoProvider(SERVICE.PROMO),
          promo_serviceV2: promoProvider(SERVICE.PROMO_V2),
          b2b_service: dataProvider(SERVICE.B2B),
          crm_service: crmProvider(SERVICE.CRM),
          supply_chain: dataProvider(SERVICE.SUPPLY_CHAIN),
          wms_service: dataProvider(SERVICE.WMS),
          harvest_shrimp: shrimpHarvestProvider(SERVICE.HARVEST_SHRIMP),
        }}
        routerProvider={{
          ...routerProvider,
          routes,
        }}
        resources={resources}
        catchAll={<ErrorPage />}
        notificationProvider={notificationProvider}
        DashboardPage={DashboardPage}
        Footer={Footer}
        Header={Header}
        Layout={Layout}
        LoginPage={Login}
        OffLayoutArea={OffLayoutArea}
        Sider={Sider}
        Title={Title}
        options={{
          warnWhenUnsavedChanges: true,
          syncWithLocation: true,
          disableTelemetry: true,
          reactQuery: {
            devtoolConfig: {
              initialIsOpen: false,
              position: 'bottom-right',
            },
          },
        }}
      >
        <RefineKbar />
      </Refine>
    </RefineKbarProvider>
  );
};

const exportApp = FEATURE.ENABLE_FEATURE_SENTRY_BROWSER_TRACING_WITH_ROUTING
  ? Sentry.withProfiler(App)
  : App;

export default exportApp;
